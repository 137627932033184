<template>
  <div>
    <div v-if="isLoadingImage" class="text-center">
      <Spinner label="Loading..." class="m-auto spinner-main"></Spinner>
    </div>
    <div v-else>
      <div class="panel-upload" v-if="images">
        <div class="panel-bg-file-img">
          <font-awesome-icon
            @click.prevent="deleteImage"
            icon="times-circle"
            class="icon-delete"
            v-if="images"
          ></font-awesome-icon>

          <c-img :src="images" class="w-100 shadow" />
        </div>
      </div>
      <div class="div-input" v-else>
        <div :class="['input-custom', { error: isValidate }]">
          <div class="d-flex justify-content-center align-items-center mt-1">
            <div class="w-100 reletive">
              <input
                :class="['custom-input']"
                type="file"
                accept="image/png, image/jpeg, capture=camera"
                name="image"
                :required="required"
                :value="fileName"
                ref="upload"
                v-on:change="handleFileChange($event)"
              />

              <div class="text-upload" @click="$refs.upload.click()">
                <font-awesome-icon
                  :icon="['fas', 'camera']"
                  class="mr-2"
                />เพิ่มรูปภาพ
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    required: {
      required: false,
      type: Boolean,
    },
    id: {
      required: true,
    },
    name: {
      required: false,
      type: String,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    label: {
      required: false,
      type: String,
    },
    img: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      images: this.img,
      fileName: this.img,
      value: this.img,
      isLoadingImage: false,
      showPreview: "",
      type: {
        all: ["image/jpeg", "image/png", "video/mp4"],
        file: ["image/jpeg", "image/png", "application/pdf"],
        pdf: ["application/pdf"],
        png: ["image/png"],
        image: ["image/jpeg", "image/png"],
        video: ["video/mp4"],
      },
    };
  },
  watch: {
    img(val) {
      console.log(val);
      this.images = val;
      this.fileName = val;
      this.value = val;
    },
  },

  methods: {
    handleImage(img) {
      this.fileName = img.name;
      this.isLoadingImage = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingImage = false;
        this.isDisable = false;
        this.showPreview = this.images;
        this.$emit("input", this.images);
        this.$emit("update:value", this.images);
      };
    },
    deleteImage() {
      this.images = "";
      this.fileName = "";
      this.showPreview = "";
      this.$emit("deleteImage", {
        type: this.fieldtype,
        questionId: this.questionId,
      });
    },
    async saveImagetoDb(img) {
      this.loadingImage = false;
      var form = {
        Base64String: img,
        is_video: 0,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      } else {
        alert("Unsuccess!");
      }
    },
    chkKeyDown(e) {
      e.preventDefault();
    },
    clearValue(e) {
      e.target.value = "";
      this.value = "";
      this.fileName = "";
      this.images = "";
      this.$emit("deleteImage");
    },
    handleFileChange(e) {
      try {
        this.value = e.target.files[0];

        var _validFileExtensions = ["image/jpeg", "image/png", "video/mp4"];
        if (e.target.files.length) {
          if (_validFileExtensions.indexOf(this.value.type) < 0) {
            this.$refs.input.value = "";

            this.$swal("เกิดข้อผิดพลาด !", "File not support", "error");
          } else if (this.value.size > 10000000) {
            this.$swal(
              "เกิดข้อผิดพลาด !",
              "This file size is too large",
              "error"
            );
          } else {
            this.handleImage(this.value);
          }
        } else {
          this.value = "";
          this.$refs.input.value = "";
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.panel-upload {
  position: relative;
}
.icon-add {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 25px;
  height: 25px;
}
.icon-delete {
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 50%;
  // width: 28px;
  font-size: 24px;
  // height: 28px;
  background: black;
  text-align: center;
  color: white;
  cursor: pointer;
  z-index: 1;
}
.panel-bg-file-img {
  img {
    width: 100%;
    height: auto;
    // aspect-ratio: 1;
    border-radius: 0.5rem;
  }
  position: relative;

  /* padding-bottom: 20%; */
  height: auto;
  // aspect-ratio: 1;
  border: 1px dashed var(--primary-color);
  // background-color: var(--secondary-color);ฟ
  color: var(--primary-color);
  border-radius: 6px;
  width: 100%;
  padding: 1rem;
  cursor: pointer;
}
input[type="file"] {
  color: transparent;
}
input[type="file"]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
  width: 100%;
  height: 100%;
  line-height: 95px;
  position: absolute;
  /* opacity: 0; */
  display: none;
}

.input-custom {
  padding: 0px;
}

input.custom-input {
  background-color: #ffffff00;
  color: #ffffff00;

  border: 1px dashed var(--primary-color);

  border-radius: 0.5rem;
  padding: 7px 10px;
  min-height: 40px;
}
label[size="lg"].btn-main,
input[size="lg"].custom-input {
  height: 45px;
}
.input-custom.error .custom-input {
  border-color: red !important;
}
::-webkit-input-placeholder {
  /* Edge */
  color: rgba(22, 39, 74, 0.4);
}
:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgba(22, 39, 74, 0.4);
}
::placeholder {
  color: rgba(22, 39, 74, 0.4);
}
.custom-input {
  display: block;
  border: none;
  width: 100%;
}
.text-desc {
  color: #16274a;
  font-size: var(--text-md);
  font-family: "Kanit-Light";
}
.display-only {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.btn-main {
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: var(--primary-color);
  color: var(--font-primary-color);
  padding: 8px 10px;
}
.btn-download-file {
  padding: 0;
  text-decoration: underline;
}
.delete-icon {
  position: absolute;
  right: 5%;
  top: 10px;
}

.ft-14 {
  font-size: var(--text-md);
}
.loading {
  width: 50px;
  margin: 10px 0;
}
.text-upload {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--primary-color);
}
.reletive {
  position: relative;
}
</style>
