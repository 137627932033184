<template>
  <div v-if="isThemeLoading">
    <router-view> <router-view name="helper" /></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      isThemeLoading: false,
    };
  },
  async created() {
    this.$bus.$on("getTheme", () => {
      this.getIconTheme();
    });
    this.getIconTheme();
  },
  methods: {
    padZero(str, len) {
      len = len || 2;
      var zeros = new Array(len).join("0");
      return (zeros + str).slice(-len);
    },
    invertColor(hex, bw) {
      if (!hex) return "#000000";
      if (hex.indexOf("#") === 0) {
        hex = hex.slice(1);
      }

      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      if (hex.length !== 6) {
        throw new Error("Invalid HEX color.");
      }
      var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
      if (bw) {
        return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
      }
      // invert color components
      r = (255 - r).toString(16);
      g = (255 - g).toString(16);
      b = (255 - b).toString(16);
      // pad each with zeros and return
      return "#" + this.padZero(r) + this.padZero(g) + this.padZero(b);
    },
    async getIconTheme() {
      await this.$store.dispatch("getTheme");

      const data = this.$store.state.theme.themeLogo;
      function hexToRgbA(hex, opacity = 0.7) {
        if (!hex) return "rbga(0,0,0,0)";
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
          c = hex.substring(1).split("");
          if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
          }
          c = "0x" + c.join("");
          return (
            "rgba(" +
            [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
            `,${opacity})`
          );
        }
        throw new Error("Bad Hex");
      }
      let bodyStyles = document.body.style;
      let lightPrimaryColor = hexToRgbA(data.result.themePrimaryColor, 0.25);
      bodyStyles.setProperty("--primary-color-light", lightPrimaryColor);
      bodyStyles.setProperty("--primary-color", data.result.themePrimaryColor);
      bodyStyles.setProperty(
        "--theme-secondary-color",
        data.result.themeSecondaryColor
      );
      bodyStyles.setProperty(
        "--secondary-color-line",
        data.result.themeSecondaryColorLine
      );
      bodyStyles.setProperty(
        "--font-color",
        this.invertColor(data.result.themeSecondaryColor, true)
      );
      bodyStyles.setProperty(
        "--theme-navigation-bar-color",
        data.result.themeNavigationBarColor
      );
      bodyStyles.setProperty(
        "--font-primary-color",
        this.invertColor(data.result.themePrimaryColor, true)
      );

      bodyStyles.setProperty(
        "--font-disable-color",
        this.invertColor(data.result.themeDisableColor, true)
      );
      this.isThemeLoading = true;
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/style";
</style>
