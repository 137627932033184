<template>
  <div>
    <b-row class="no-gutters align-items-center" style="min-height: 35px">
      <b-col
        :cols="hasSearch ? '' : '3'"
        md="4"
        sm="5"
        lg="6"
        class="header-wrapper"
      >
        <h4
          :class="[
            !hasSearch ? 'text-nowrap' : '',
            'mr-sm-4 mb-0 header-tablepage',
          ]"
        >
          {{ title }}
        </h4>
        <slot name="filter-title"></slot>
      </b-col>
      <b-col
        md="8"
        sm="7"
        lg="6"
        class="text-right mt-2 mt-sm-0"
        v-if="hasSearch"
      >
        <b-input-group>
          <b-form-input
            type="text"
            id="header-search-bar"
            class="search-bar"
            @keyup.enter="handleSearch"
            :placeholder="placeholder"
            v-model="filter.search"
            :disabled="tab === 3 ? true : false"
          >
          </b-form-input>
          <b-input-group-append is-text class="mr-2">
            <b-iconstack
              font-scale="2"
              type="submit"
              @click="handleSearch"
              :disabled="tab === 3 ? true : false"
            >
              <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
            </b-iconstack>
          </b-input-group-append>
          <b-button
            v-if="hasFilter"
            @click.prevent="sidebarFilter"
            class="btn-filter mr-2"
            :disabled="tab === 3 ? true : false"
          >
            <font-awesome-icon icon="filter" class="d-md-none" />
            <span class="d-none d-md-block">
              <font-awesome-icon icon="filter" class="pointer" />
              <span class="btn-text">Filter</span>
            </span>
          </b-button>
          <b-dropdown
            v-if="hasDropdown"
            id="dropdown"
            right
            class="btn-mobile mr-2"
          >
            <template #button-content>
              <span class="d-none d-md-inline-flex btn-text mr-1">Action</span>
            </template>
            <slot name="content-dropdown"> </slot>
          </b-dropdown>

          <button
            @click="$emit('btnMethods')"
            type="button"
            class="btn button btn-mobile"
            v-else-if="hideCreate"
          >
            <font-awesome-icon icon="plus" class="d-md-none" />
            <span class="d-none d-md-block">
              <font-awesome-icon icon="plus-square" class="pointer" />
              <span class="btn-text ml-2">Create New</span>
            </span>
          </button>
          <button
            @click="$emit('export')"
            type="button"
            class="btn button btn-mobile mr-2"
            v-if="hasExport"
          >
            <font-awesome-icon icon="file-export" class="d-md-none" />
            <span class="d-none d-md-block">
              <font-awesome-icon icon="file-export" class="pointer" />
              <span class="btn-text">Export </span>
            </span>
          </button>
          <router-link :to="routerPath" v-if="routerPath" exact>
            <button type="button" class="btn button btn-mobile">
              <font-awesome-icon icon="plus" class="d-md-none" />
              <span class="d-none d-md-block">
                <font-awesome-icon icon="plus-square" class="pointer" />
                <span class="btn-text ml-2">Create New</span>
              </span>
            </button>
          </router-link>
        </b-input-group>
      </b-col>
      <b-col v-else class="text-right" md="8" cols="9" sm="7" lg="6">
        <slot name="frontAction"></slot>
        <b-button
          v-if="hasFilter"
          @click.prevent="sidebarFilter"
          class="btn-filter"
          :disabled="tab === 3 ? true : false"
        >
          <font-awesome-icon icon="filter" class="d-md-none" />
          <span class="d-none d-md-block">
            <font-awesome-icon icon="filter" class="pointer" />

            <span class="btn-text"> Filter </span>
          </span>
        </b-button>
        <b-dropdown v-if="hasDropdown" id="dropdown" right class="btn-mobile">
          <template #button-content>
            <span class="d-none d-md-inline-flex btn-text mr-1">Action</span>
          </template>
          <slot name="content-dropdown">
            <b-dropdown-item :href="routerPath">
              <router-link :to="routerPath" id="create-new-staff">
                <font-awesome-icon icon="plus" class="d-md-none" />
                <span class="d-none d-md-block">
                  <font-awesome-icon icon="plus-square" class="pointer" />
                  <span class="btn-text ml-2">Create new</span>
                </span>
              </router-link>
            </b-dropdown-item>
            <b-dropdown-item href="#">
              <font-awesome-icon icon="filter" class="d-md-none" />
              <span class="d-none d-md-block">
                <font-awesome-icon icon="file-export" class="pointer" />
                <span class="btn-text">Export File</span>
              </span>
            </b-dropdown-item>
          </slot>
        </b-dropdown>
        <router-link :to="routerPath" v-if="routerPath">
          <button type="button" class="btn button btn-mobile">
            <font-awesome-icon icon="plus" class="d-md-none" />
            <span class="d-none d-md-block">
              <font-awesome-icon icon="plus-square" class="pointer" />
              <span class="btn-text">Create new</span>
            </span>
          </button>
        </router-link>
        <button
          @click="$emit('export')"
          type="button"
          class="btn button btn-mobile ml-2"
          v-if="hasExport"
        >
          <font-awesome-icon icon="file-export" class="d-md-none" />
          <span class="d-none d-md-block">
            <font-awesome-icon icon="file-export" class="pointer" />
            <span class="btn-text">Export </span>
          </span>
        </button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    filter: {
      required: false,
      type: Object,
    },
    placeholder: {
      required: false,
      type: String,
    },
    btnFilter: {
      required: false,
      type: Boolean,
    },
    btnExport: {
      required: false,
      type: Boolean,
    },
    tab: {
      required: false,
      type: Number,
    },
    routerPath: {
      required: false,
    },
    hasDropdown: {
      required: false,
      default: true,
    },
    hasFilter: {
      required: false,
      default: true,
    },
    hasSearch: {
      default: true,
      required: false,
    },
    btnMethods: {
      required: false,
    },
    hideCreate: {
      required: false,
      default: false,
    },
    hasExport: {
      required: false,
      default: false,
    },
  },
  methods: {
    autoSearch() {
      this.$emit("search", this.filter);
    },
    handleSearch(e) {
      if (e.type === "keyup") {
        if (e.keyCode === 13) {
          this.$emit("search", this.filter);
        }
      } else if (e.type === "click") {
        this.$emit("search", this.filter);
      }
    },
    exportExcel() {
      this.$emit("confirmExport");
    },
    sidebarFilter() {
      this.$emit("sidebar");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
::v-deep .btn-filter button,
.btn-filter {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
  font-weight: 100;
}
::v-deep .form-control:disabled,
.form-control[readonly] {
  background-color: #fafafa;
}
::v-deep .dropdown-toggle.btn-secondary {
  background-color: #fff;
  color: var(--primary-color);
  border-color: var(--primary-color);
}
::v-deep .dropdown-item:active {
  text-decoration: none;
  background-color: #fff;
  color: var(--primary-color) !important;
}
::v-deep .dropdown-menu {
  width: fit-content;
  li:hover {
    text-decoration: none;
    background-color: #fff;
    color: var(--primary-color) !important;
  }
}
input.search-bar {
  box-shadow: inset 0 1px 2px hsl(0deg 0% 7% / 10%);
}
input#header-search-bar::placeholder {
  text-transform: capitalize;
}
.input-group > .input-group-append:not(:last-child) > .input-group-text {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
</style>
