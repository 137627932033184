<template>
  <b-modal
    size="md"
    centered
    title="Telephone Numbers"
    v-model="isShow"
    hide-header-close
    ok-title="Add Point"
    hide-footer
  >
    <table class="w-100 optional-table" v-if="!add">
      <thead>
        <th class="text-center w-2 py-2">Name</th>
        <th class="text-center w-2 py-2">Telephone Number</th>
      </thead>
      <tbody>
        <tr>
          <td class="text-center w-2 py-2">Primary Telephone.</td>
          <td class="text-center w-2 py-2">
            <b-img
              :src="`https://flagcdn.com/48x36/${optionalList.telephone_country_code.toLowerCase()}.png`"
              style="width: 18px; height: 14px; margin-right: 5px"
            ></b-img>
            ({{ optionalList.telephone_country_dial_code }})
            {{ convertTelePhone(optionalList.telephone) }}
          </td>
        </tr>
        <template v-if="optionalTelephone">
          <tr
            v-for="(items, index) of optionalTelephone"
            :key="index + '-telephone'"
          >
            <td class="text-center w-2 py-2">Optional Tel. {{ index + 1 }}</td>
            <td class="text-center w-2 py-2">{{ items }}</td>
          </tr>
        </template>
      </tbody>
    </table>
    <template v-else>
      <template v-for="(items, index) of listOptional">
        <div class="d-inline-flex" :key="index + '-telephone'">
          <InputTelephoneWithCountry
            :textFloat="`Optional Telephone ${index + 1}.`"
            :placeholder="`Optional Telephone ${index + 1}.`"
            v-model="items.tel"
            :countryShow="false"
            :key="index"
            @delete="deleteItems(index)"
            :removeIcon="listOptional.length == 1"
          />
        </div>
      </template>
      <b-col
        class="text-center"
        v-if="telephoneLimit == 0 || listOptional.length < telephoneLimit"
        ><b-button
          variant="custom-outline-primary"
          @click="pushList"
          :disabled="disabled"
        >
          Add Optional Telephone
          <span v-if="telephoneLimit != 0 && telephoneLimit != ''">
            ({{ listOptional.filter((el) => el.tel).length }}/{{
              telephoneLimit
            }})
          </span>
        </b-button>
      </b-col>
    </template>
    <div class="text-center mt-3" :class="add ? 'content-between' : ''">
      <b-button variant="custom-primary" @click="hide">Cancel</b-button>
      <b-button variant="custom-primary" @click="addOptional" v-if="add"
        >Confirm</b-button
      >
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    optionalList: {
      required: true,
    },
    add: {
      required: false,
      default: false,
    },
    telephoneLimit: {
      required: false,
    },
  },
  data() {
    return {
      isShow: false,
      listOptional: [],
    };
  },
  computed: {
    optionalTelephone() {
      if (!this.add && this.optionalList.optional_telephone)
        if (this.optionalList.optional_telephone.length > 0)
          return (
            this.optionalList.optional_telephone &&
            this.optionalList.optional_telephone.split(",").filter((el) => el)
          );
      return this.add ? [{ tel: "" }] : [];
    },
    disabled() {
      return this.listOptional.some((el) => el.tel == "");
    },
  },
  methods: {
    show() {
      if (this.add && this.optionalList) {
        if (this.optionalList.length > 0)
          this.listOptional = this.optionalList.map((el) => {
            return { tel: el };
          });
        else this.listOptional = [{ tel: "" }];
      }
      this.isShow = true;
    },
    hide() {
      this.isShow = false;
    },
    deleteItems(val) {
      // console.log(val);
      this.listOptional.splice(val, 1);
      this.$nextTick(
        () =>
          (this.listOptional = JSON.parse(JSON.stringify(this.listOptional)))
      );
    },
    addOptional() {
      this.$emit("update-value", this.listOptional);
    },
    pushList() {
      this.listOptional.push({ tel: "" });
    },
  },
};
</script>

<style>
.optional-table tbody tr:nth-child(odd) {
  background: whitesmoke;
}
</style>
