<template>
  <div>
    <div v-if="isLoadingImage" class="text-center">
      <b-spinner class="align-middle"></b-spinner>
    </div>
    <div v-else>
      <div class="panel-upload" v-if="images">
        <div class="list-upload-file">
          <div class="d-flex align-items-center">
            <font-awesome-icon
              v-if="images.includes('.pdf')"
              :icon="'file'"
              class="file-image-icon"
            ></font-awesome-icon>
            <c-img
              :src="images"
              style="width: 30px; height: auto; margin-right: 15px"
              v-else
            ></c-img>
            <div>
              {{ ImageName || images }}
              <div>
                {{ (file.size / 1024 / 1024).toFixed(2) }} MB &#x2022; Updated
                Date :
                {{ $moment(dateTime).format("DD MMM YYYY (HH:mm)") }}
              </div>
            </div>
          </div>
          <div>
            <font-awesome-icon
              icon="download"
              class="mr-2"
              @click="downloadFile(images)"
            ></font-awesome-icon>
            <font-awesome-icon
              icon="search"
              class="mr-2"
              @click="overlay = true"
            ></font-awesome-icon>
            <font-awesome-icon
              icon="trash-alt"
              @click="clearValue"
            ></font-awesome-icon>
          </div>
          <!-- <c-img :src="images" class="w-100 shadow" /> -->
        </div>
      </div>
      <div class="div-input" v-else>
        <div :class="['input-custom ', { error: isValidate }]">
          <div class="d-flex justify-content-center align-items-center mt-1">
            <div class="w-100 reletive">
              <input
                :class="['custom-input cursor-pointer']"
                type="file"
                :accept="type.file"
                name="image"
                :required="required"
                :value="fileName"
                ref="upload"
                v-on:change="handleFileChange($event)"
              />

              <div
                class="text-upload cursor-pointer"
                @click="$refs.upload.click()"
              >
                <font-awesome-icon icon="plus-circle" class="mr-2" />
                {{ textUpload }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CardOverLay
      :img="images"
      @close="overlay = false"
      v-if="overlay"
      :type="images.includes('.pdf') ? 'pdf' : 'image'"
    />
  </div>
</template>

<script>
import CardOverLay from "@/components/report/customer/detail/components/CardOverLay";
export default {
  components: {
    CardOverLay,
  },
  props: {
    required: {
      required: false,
      type: Boolean,
    },
    id: {
      required: true,
    },
    name: {
      required: false,
      type: String,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    label: {
      required: false,
      type: String,
    },
    img: {
      required: false,
      type: String,
    },
    ImageName: {
      required: false,
      default: null,
    },
    dateTime: {
      required: false,
    },
    textUpload: {
      required: false,
      type: String,
      default: "Upload Document",
    },
  },
  data() {
    return {
      images: this.img,
      fileName: this.img,
      value: this.img,
      isLoadingImage: false,
      showPreview: "",
      type: {
        all: ["image/jpeg", "image/png", "video/mp4"],
        file: ["image/jpeg", "image/png", "application/pdf"],
        pdf: ["application/pdf"],
        png: ["image/png"],
        image: ["image/jpeg", "image/png"],
        video: ["video/mp4"],
      },
      overlay: false,
      file: {
        type: "",
        size: 0,
      },
    };
  },
  computed: {},
  watch: {
    img(val) {
      //
      return (this.images = val);
    },
    images() {
      this.checkSizeAndType();
      return;
    },
  },
  mounted() {},
  methods: {
    handleImage(img) {
      this.fileName = img.name;
      this.isLoadingImage = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        const imagePath = await this.saveImagetoDb(reader.result, img.type);
        if (imagePath == false) return this.errorAlert("UPLOAD IMAGE ERROR");
        this.images = imagePath;
        await this.checkSizeAndType();
        this.isLoadingImage = false;
        this.isDisable = false;
        this.showPreview = this.images;
        this.$emit("input", this.images);
        this.$emit("setFileName", img.name);
        this.$emit("update:value", this.images);
        this.$emit("upload");
      };
    },
    deleteImage() {
      this.images = "";
      this.fileName = "";
      this.showPreview = "";
      this.$emit("input", "");
      this.$emit("upload");
    },
    async saveImagetoDb(img, type) {
      this.loadingImage = false;
      var form = {
        Base64String: img,
        is_video: 0,
        is_file: type == "application/pdf" ? true : false,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      } else {
        return false;
      }
    },
    chkKeyDown(e) {
      e.preventDefault();
    },
    clearValue(e) {
      this.images = "";
      this.fileName = "";
      this.showPreview = "";
      this.$emit("input", "");
      this.$emit("deleteImage");
    },
    handleFileChange(e) {
      try {
        this.value = e.target.files[0];
        if (e.target.files.length) {
          var filesize = (this.value.size / 1024 / 1024).toFixed(4);
          if (this.type.file.indexOf(this.value.type) < 0) {
            this.value = "";

            this.errorAlert("File not support", "เกิดข้อผิดพลาด !");
          } else if (filesize > 2.5) {
            this.errorAlert("This file size is too large", "เกิดข้อผิดพลาด !");
          } else {
            this.handleImage(this.value);
          }
        } else {
          this.value = "";
        }
      } catch (error) {
        console.log(error);
      }
    },
    async downloadFile(path) {
      const image = await fetch(this.images);
      const imageBlob = await image.blob();
      let type = imageBlob.type.split("/")[1];
      const imageURL = URL.createObjectURL(imageBlob);

      const link = document.createElement("a");
      link.href = imageURL;
      link.download = this.ImageName ? this.ImageName : `${this.label}.${type}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async checkSizeAndType() {
      if (this.images) {
        const image = await this.$Axios(this.images, {
          responseType: "blob",
        });
        console.log(image);
        const imageBlob = await image.data;
        let type = imageBlob.type.split("/")[1];

        return (this.file = { type, size: imageBlob.size });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.panel-upload {
  position: relative;
}
.icon-add {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 25px;
  height: 25px;
}
.icon-delete {
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 50%;
  // width: 28px;
  font-size: 24px;
  // height: 28px;
  background: black;
  text-align: center;
  color: white;
  cursor: pointer;
  z-index: 1;
}
.file-image-icon {
  font-size: 24px;
  margin-right: 15px;
}
.list-upload-file {
  img {
    width: 100%;
    height: auto;
    // aspect-ratio: 1;
    border-radius: 0.5rem;
  }
  position: relative;

  /* padding-bottom: 20%; */
  height: auto;
  // aspect-ratio: 1;
  border: 1px dashed var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  color: var(--primary-color);
  border-radius: 6px;
  width: 100%;
  padding: 1rem;
  cursor: pointer;
}
input[type="file"] {
  color: transparent;
}
input[type="file"]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
  width: 100%;
  height: 100%;
  line-height: 95px;
  position: absolute;
  /* opacity: 0; */
  display: none;
}

.input-custom {
  padding: 0px;
}

input.custom-input {
  background-color: #ffffff00;
  color: #ffffff00;

  border: 1px dashed var(--primary-color);

  border-radius: 0.5rem;
  padding: 7px 10px;
  min-height: 40px;
}
label[size="lg"].btn-main,
input[size="lg"].custom-input {
  height: 45px;
}
.input-custom.error .custom-input {
  border-color: red !important;
}
::-webkit-input-placeholder {
  /* Edge */
  color: rgba(22, 39, 74, 0.4);
}
:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgba(22, 39, 74, 0.4);
}
::placeholder {
  color: rgba(22, 39, 74, 0.4);
}
.custom-input {
  display: block;
  border: none;
  width: 100%;
}
.text-desc {
  color: #16274a;
  font-size: var(--text-md);
  font-family: "Kanit-Light";
}
.display-only {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.btn-main {
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: var(--primary-color);
  color: var(--font-primary-color);
  padding: 8px 10px;
}
.btn-download-file {
  padding: 0;
  text-decoration: underline;
}
.delete-icon {
  position: absolute;
  right: 5%;
  top: 10px;
}

.ft-14 {
  font-size: var(--text-md);
}
.loading {
  width: 50px;
  margin: 10px 0;
}
.text-upload {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--primary-color);
}
.reletive {
  position: relative;
}
</style>
